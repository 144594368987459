/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import siteConfig from '../../../data/SiteConfig';

export default function content() {
  return (
    <>
      <div className="site-wrap">
        <div className="site-container">
          <div className="site-content site-content-posts site-content-posts-large">
            {/* content */}
            <article>
              <time dateTime="2021-02-05T12:49">
                Updated: February 05, 2021
              </time>
              <blockquote>
                <p>
                  This website or "services" or "apps" or "software" is only
                  available for Nepal jurisdiction. If you are not a legal
                  entity or citizens of Nepal - you are not permitted to use
                  this service.
                </p>
              </blockquote>
              <ol>
                <li>
                  Terms
                  <p className="terms-clause">
                    By accessing the website at{' '}
                    <a href={siteConfig.siteUrl}>{siteConfig.siteUrl}</a>, you
                    are agreeing to be bound by these terms of service, all
                    applicable laws, and regulations of Bagmati State (Formerly
                    State 3) NEPAL, and agree that you are responsible for
                    compliance with any applicable local laws. If you do not
                    agree with any of these terms, you are prohibited from using
                    or accessing this site. The materials contained in this
                    website are protected by applicable copyright and trademark
                    law.
                  </p>
                </li>
                <li>
                  Use License
                  <p className="terms-clause" />
                  <ol>
                    <li>
                      Permission is granted to use our web application -{' '}
                      <strong>
                        {' '}
                        DanfeBooks <sup>®</sup>{' '}
                      </strong>{' '}
                      , a cloud-based internet application and the materials
                      (information or software) on OPTIMUM FUTURIST TECH Pvt.
                      Ltd.'s website for personal or commercial purposes for any
                      legal entity that is bound by Nepal Government and its
                      territories
                      <br />
                      This is the grant of a license only to use, not a transfer
                      of title, and under this license you will not:
                      <ol>
                        <li>modify or copy the materials;</li>
                        <li>use the materials for any illegal purpose;</li>
                        <li>
                          attempt to decompile or reverse engineer any software
                          contained on OPTIMUM FUTURIST TECH Pvt. Ltd.'s
                          websites;
                        </li>
                        <li>
                          remove any copyright or other proprietary notations
                          from the materials; or
                        </li>
                        <li>
                          transfer the materials to another person or "mirror"
                          the materials on any other server.
                        </li>
                      </ol>
                    </li>
                    <li>
                      This license shall automatically terminate if you violate
                      any of these restrictions and your account may be
                      terminated by OPTIMUM FUTURIST TECH Pvt. Ltd. at any time.
                      Upon terminating your account, and or permissions to the
                      viewing of these materials or upon the termination of this
                      license, you must destroy any downloaded materials in your
                      possession whether in electronic or printed format. You
                      shall not participate in any class lawsuit actions against
                      any of your data losses due to account termination.
                    </li>
                  </ol>
                  <p />
                </li>
                <li>
                  Disclaimer
                  <p className="terms-clause" />
                  <ol>
                    <li>
                      The app services on OPTIMUM FUTURIST TECH Pvt. Ltd.'s web
                      application -{' '}
                      <strong>
                        {' '}
                        DanfeBooks <sup>®</sup>{' '}
                      </strong>{' '}
                      are provided on an 'as is' basis. OPTIMUM FUTURIST TECH
                      Pvt. Ltd. makes no warranties, expressed or implied, and
                      hereby disclaims and negates all other warranties
                      including, without limitation, implied warranties or
                      conditions of merchantability, fitness for a particular
                      purpose, or non-infringement of intellectual property or
                      other violation of rights.
                    </li>
                    <li>
                      Further, OPTIMUM FUTURIST TECH Pvt. Ltd. does not warrant
                      or make any representations concerning the accuracy,
                      likely results, or reliability of the use of the materials
                      on its software, web applications, mobile application,
                      website or otherwise relating to such materials or on any
                      sites linked to this site. <br /> You must consult with an
                      SME, certified or licensed accountants and auditors -
                      should you be using our application to handle legal and
                      compliance matters like Tax returns filing and
                      proceedings.
                    </li>
                  </ol>
                  <p />
                </li>
                <li>
                  Limitations
                  <p className="terms-clause">
                    In no event shall OPTIMUM FUTURIST TECH Pvt. Ltd. or its
                    founders, employees, partners or suppliers be liable for any
                    damages (including, without limitation, damages for loss of
                    data or profit, or due to business interruption) arising out
                    of the use or inability to use the materials on OPTIMUM
                    FUTURIST TECH Pvt. Ltd.'s website, even if OPTIMUM FUTURIST
                    TECH Pvt. Ltd. authorized representative has been notified
                    orally or in writing of the possibility of such damage.
                    Because some jurisdictions do not allow limitations on
                    implied warranties, or limitations of liability for
                    consequential or incidental damages, these limitations may
                    not apply to you.
                  </p>
                </li>
                <li>
                  Accuracy of materials
                  <p className="terms-clause">
                    The materials appearing on OPTIMUM FUTURIST TECH Pvt. Ltd.'s
                    web applications or website could include technical,
                    typographical, or photographic errors. OPTIMUM FUTURIST TECH
                    Pvt. Ltd. does not warrant that any of the materials on its
                    web applications or website are accurate, complete or
                    current. OPTIMUM FUTURIST TECH Pvt. Ltd. may make changes to
                    the materials contained on its web applications or website
                    at any time without notice. However, OPTIMUM FUTURIST TECH
                    Pvt. Ltd. does not make any commitment to update the
                    materials.
                  </p>
                </li>
                <li>
                  Links
                  <p className="terms-clause">
                    OPTIMUM FUTURIST TECH Pvt. Ltd. has not reviewed all of the
                    other websites linked to its website and is not responsible
                    for the contents of any such linked websites. The inclusion
                    of any link does not imply endorsement by OPTIMUM FUTURIST
                    TECH Pvt. Ltd. of the site. Use of any such linked website
                    is at the user's own risk.
                  </p>
                </li>
                <li>
                  Modifications
                  <p className="terms-clause">
                    OPTIMUM FUTURIST TECH Pvt. Ltd. may revise these terms of
                    service for its website at any time without notice. By using
                    this website you are agreeing to be bound by the then
                    current version of these terms of service.
                  </p>
                </li>
                <li>
                  Governing Law
                  <p className="terms-clause">
                    These terms and conditions are governed by and construed in
                    accordance with the laws of Bagmati State (Formerly State 3)
                    Nepal and you irrevocably submit to the exclusive
                    jurisdiction of the courts in this district.
                  </p>
                </li>
              </ol>
              <br />
            </article>
          </div>
        </div>
      </div>
    </>
  );
}
