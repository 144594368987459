import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

function Heading() {
  const intl = useIntl();
  return (
    <>
      <div className="masthead masthead-blog">
        <div className="masthead-contents">
          <div className="masthead-text">
            <h1 className="small">
              {intl.formatMessage({ id: 'footer.termsofServices' })}
            </h1>
            <p className="sub-lead">
              You agreed to our terms of services when you signed up for an
              account on danfebooks
              <sup>®</sup>
            </p>
          </div>
        </div>
      </div>
      <div className="masthead-placeholder" />
    </>
  );
}

export default Heading;
